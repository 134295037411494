import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

export default function PrimaryButton(props) {
  if (props.blue) {
    return (
      <button
        className={`${styles.primary_button} ${styles.primary_button__blue}`}
        type={props.type}
        onClick={props.onClick}
      >
        <div className={styles.primary_button__container}>
          {props.children}
          <svg
            className={styles.primary_button__container__icon}
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
          >
            <path d="M23.75,12.83l0-.06a.69.69,0,0,0,.1-.19.38.38,0,0,0,0-.1A1.27,1.27,0,0,0,24,12.3a1.51,1.51,0,0,0,0-.6,1.27,1.27,0,0,0-.06-.18.38.38,0,0,0,0-.1.69.69,0,0,0-.1-.19l0-.06a1.45,1.45,0,0,0-.19-.23L13.06.44a1.5,1.5,0,0,0-2.12,2.12l7.94,7.94H1.5a1.5,1.5,0,0,0,0,3H18.88l-7.94,7.94a1.49,1.49,0,0,0,0,2.12,1.5,1.5,0,0,0,2.12,0l10.5-10.5A1.45,1.45,0,0,0,23.75,12.83Z" />
          </svg>
        </div>
      </button>
    );
  } else {
    return (
      <button className={styles.primary_button} type={props.type} onClick={props.onClick}>
        <div className={styles.primary_button__container}>
          {props.children}
          <svg
            className={styles.primary_button__container__icon}
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
          >
            <path d="M23.75,12.83l0-.06a.69.69,0,0,0,.1-.19.38.38,0,0,0,0-.1A1.27,1.27,0,0,0,24,12.3a1.51,1.51,0,0,0,0-.6,1.27,1.27,0,0,0-.06-.18.38.38,0,0,0,0-.1.69.69,0,0,0-.1-.19l0-.06a1.45,1.45,0,0,0-.19-.23L13.06.44a1.5,1.5,0,0,0-2.12,2.12l7.94,7.94H1.5a1.5,1.5,0,0,0,0,3H18.88l-7.94,7.94a1.49,1.49,0,0,0,0,2.12,1.5,1.5,0,0,0,2.12,0l10.5-10.5A1.45,1.45,0,0,0,23.75,12.83Z" />
          </svg>
        </div>
      </button>
    );
  }
}

PrimaryButton.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
};

PrimaryButton.defaultProps = {
  text: 'Download',
  type: 'button',
};
