import React from 'react';
import styles from './styles.module.scss';

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer__container}>
        <div className={styles.footer__container__socials}>
          <img
            className={styles.footer__container__socials__logo}
            src="/symbols/logo-deslimmeriken-text.svg"
            alt="De Slimmeriken"
          ></img>
          <div className={styles.footer__container__socials__items}>
            <a
              href="https://www.linkedin.com/company/de-slimmeriken/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/symbols/icon-social-linkedin.svg" alt="LinkedIn"></img>
            </a>
            <a
              href="https://www.facebook.com/De-Slimmeriken-104172237839455/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/symbols/icon-social-facebook.svg" alt="Facebook"></img>
            </a>
            <a
              href="https://www.instagram.com/deslimmeriken/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/symbols/icon-social-instagram.svg" alt="Instagram"></img>
            </a>
          </div>
        </div>

        <div
          className={`${styles.footer__container__section} ${styles.footer__container__address}`}
        >
          <h3 className={styles.footer__container__section__title}>Postadres</h3>
          <ul className={styles.footer__container__section__items}>
            <li>Jan van Eyckstraat 27</li>
            <li>1816 DA</li>
            <li>Alkmaar</li>
          </ul>
        </div>

        <div
          className={`${styles.footer__container__section} ${styles.footer__container__business}`}
        >
          <h3 className={styles.footer__container__section__title}>Business</h3>
          <ul className={styles.footer__container__section__items}>
            <li>info@deslimmeriken.nl</li>
            <li>+31 (0) 6 39 27 42 00</li>
            <li>KvK 57860181</li>
          </ul>
        </div>

        <div className={`${styles.footer__container__section} ${styles.footer__container__links}`}>
          <h3 className={styles.footer__container__section__title}>Links</h3>
          <ul className={styles.footer__container__section__items}>
            <li>
              <a href="/documents/Privacy - De Slimmeriken.pdf" target="_blank">
                Privacy
              </a>
            </li>
          </ul>
        </div>

        <div className={styles.footer__container__copyright}>
          © {new Date().getFullYear()} De Slimmeriken. Created by{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://dev.kitchen/">
            dev.kitchen
          </a>
        </div>
      </div>
    </footer>
  );
}
