/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';

import '../styles/tiny-slider.css';
import '../styles/main.scss';
import '../styles/normalize.css';

import Header from './header';
import Main from './main';
import Footer from './footer';

require('typeface-lato');
require('typeface-raleway');

const Layout = ({ children }) => {
  return (
    <>
      <Header></Header>
      <Main>{children}</Main>
      <Footer></Footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
