import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.module.scss';
import OffCanvas from 'react-aria-offcanvas';
import PrimaryButton from '../primary-button';

function MobileMenu(props) {
  return (
    <div className={styles.header__mobile} id="mobile-menu">
      <nav className={styles.header__mobile__items}>
        <a
          className={styles.header__mobile__items__item}
          href="https://slimmeriken.nl/"
          target="_blank"
          rel="noreferrer"
        >
          Slimmeriken Shop
        </a>
        <Link
          onClick={props.toggleMobileNavigation}
          className={styles.header__mobile__items__item}
          to="/#diensten"
        >
          <span>Diensten</span>
        </Link>
        <Link
          onClick={props.toggleMobileNavigation}
          className={styles.header__mobile__items__item}
          to="/#werkwijze"
        >
          <span>Werkwijze</span>
        </Link>
        <Link
          onClick={props.toggleMobileNavigation}
          className={styles.header__mobile__items__item}
          to="/#oplossingen"
        >
          <span>Oplossingen</span>
        </Link>
        <Link
          onClick={props.toggleMobileNavigation}
          className={styles.header__mobile__items__item}
          to="/#team"
        >
          <span>Team</span>
        </Link>
        <Link
          onClick={props.toggleMobileNavigation}
          className={styles.header__mobile__items__item}
          to="/#faq"
        >
          <span>FAQ</span>
        </Link>
        <Link
          onClick={props.toggleMobileNavigation}
          className={styles.header__mobile__items__item}
          to="/#contact"
        >
          <span>Contact</span>
        </Link>
      </nav>
    </div>
  );
}

export default class Header extends React.Component {
  state = {
    mobileMenuIsOpen: false,
  };

  previousScrollPosition = 0;

  componentDidMount() {
    this.previousScrollPosition = window.pageYOffset;
    window.addEventListener('scroll', this.handleScroll, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    var currentScrollPosition = window.pageYOffset;

    if (!this.state.mobileMenuIsOpen) {
      if (this.previousScrollPosition > currentScrollPosition) {
        document.getElementById('header').style.top = '0';
      } else {
        document.getElementById('header').style.top = '-200px';
      }
    }

    this.previousScrollPosition = currentScrollPosition;
  };

  toggleMobileNavigation = () => {
    this.setState({ mobileMenuIsOpen: !this.state.mobileMenuIsOpen });
    document.body.style.overflow = 'scroll';

    var header = document.getElementById('header');
    header.classList.toggle(styles.header__mobile__active);
  };

  render() {
    return (
      <header className={styles.header} id="header">
        <div className={styles.header__container}>
          <div className={styles.header__container__logo}>
            <Link to="/">
              <img alt={this.props.title} src="/images/logo.svg"></img>
            </Link>
          </div>
          <nav className={styles.header__container__items}>
            <Link
              className={styles.header__container__items__item}
              activeClassName={styles.header__container__items__item__active}
              to="/#diensten"
            >
              Diensten
            </Link>
            <Link
              className={styles.header__container__items__item}
              activeClassName={styles.header__container__items__item__active}
              to="/#werkwijze"
            >
              Werkwijze
            </Link>
            <Link
              className={styles.header__container__items__item}
              activeClassName={styles.header__container__items__item__active}
              to="/#oplossingen"
            >
              Oplossingen
            </Link>{' '}
            <Link
              className={styles.header__container__items__item}
              activeClassName={styles.header__container__items__item__active}
              to="/#team"
            >
              Team
            </Link>
            <Link
              className={styles.header__container__items__item}
              activeClassName={styles.header__container__items__item__active}
              to="/#faq"
            >
              FAQ
            </Link>
            <Link
              className={styles.header__container__items__item}
              activeClassName={styles.header__container__items__item__active}
              to="/#contact"
            >
              Contact
            </Link>
            <a
              className={styles.header__container__items__item}
              href="https://slimmeriken.nl/"
              target="_blank"
              rel="noreferrer"
            >
              <PrimaryButton blue>Slimmeriken Shop</PrimaryButton>
            </a>
          </nav>

          <button
            className={
              this.state.mobileMenuIsOpen
                ? `${styles.header__container__burger} ${styles.header__container__burger__active}`
                : styles.header__container__burger
            }
            aria-label="Menu"
            aria-controls="menu"
            aria-expanded={this.state.mobileMenuIsOpen}
            tabIndex={0}
            onClick={this.toggleMobileNavigation}
          >
            <div className={styles.header__container__burger__top}></div>
            <div className={styles.header__container__burger__middle}></div>
            <div className={styles.header__container__burger__bottom}></div>
          </button>

          <OffCanvas
            className={styles.header__mobile}
            overlayClassName={styles.header__mobile__overlay}
            height="100vh"
            width="100vw"
            isOpen={this.state.mobileMenuIsOpen}
            onClose={this.toggleMobileNavigation}
            position="top"
            labelledby="menu-button"
          >
            <MobileMenu toggleMobileNavigation={this.toggleMobileNavigation}></MobileMenu>
          </OffCanvas>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  title: PropTypes.string,
};

Header.defaultProps = {
  title: 'De Slimmeriken',
};
